// Vanilla JS ================

// function showOverlay() {
//   var overlay = document.getElementById('overlay');
//
//   if (overlay.classList.contains("active")) {
//     overlay.classList.remove("active");
//   } else {
//     overlay.classList.add("active");
//   }
// }
//https://stackoverflow.com/questions/2112710/css-style-visibility-not-behaving-as-expected

////////////// projects

console.log(
  `%c ________________________________________
  _    _      _ _       _
 | |  | |    | | |     | |
 | |__| | ___| | | ___ | |
 |  __  |/ _ \\ | |/ _ \ | |
 | |  | |  __/ | | (_) |_|
 |_|  |_|\___ |_|_|\ ___/(_)`,
  "font-family:monospace;color: #222;"
);

console.log(
  "Hey, I also like looking at code, nice! This is version 0.4, you will find bugs."
);

//Logo colour switch
$(document).on("scroll", function () {
  var scrollPos =
    $(document).scrollTop() +
    $("#logo").position().top +
    $("#logo").height() / 2;
  $(".logo-invert").each(function () {
    var refElement = $(this);
    if (
      refElement.position().top <= scrollPos &&
      refElement.position().top + refElement.height() > scrollPos
    ) {
      $("#logo").addClass("inverted");
      $("#overlay-hero__button").addClass("inverted");
      // found one, so exit .each
      return false;
    } else {
      $("#logo").removeClass("inverted");
      $("#overlay-hero__button").removeClass("inverted");
    }
  });
});

// JQuery =================

//logo transitions over sections / colour swap
// $(document).ready(function () {
//   //Works but a bit jittery
//   //https://codepen.io/eighthday/pen/MKqBjX
//   function logoSwitch() {
//     $(".logo__alt").each(function () {
//       $(this).css(
//         "top",
//         $(".logo__default").offset().top -
//           $(this).closest(".logo-switch").offset().top
//       );
//     });
//   }

//   $(document).scroll(function () {
//     logoSwitch();
//   });

//   logoSwitch();

//   function navSwitch() {
//     $(".header-mobile-nav__alt").each(function () {
//       $(this).css(
//         "top",
//         $(".header-mobile-nav").offset().top -
//           $(this).closest(".nav-switch").offset().top
//       );
//     });
//   }

//   $(document).scroll(function () {
//     navSwitch();
//   });

//   navSwitch();
//   //home logo switch
// });

//Mobile menu v2

//SLIDE IN

//for hero hamburger
var $slider = document.getElementById("menu-mobile__overlay");
// var $toggle = document.getElementById('menu-mobile__hamburger');

// $toggle.addEventListener('click', function() {
//   var isOpen = $slider.classList.contains('slide-in');
//   console.log(isOpen);
//   $slider.setAttribute('class', isOpen ? 'slide-out' : 'slide-in');
// });

//for body hamburger - class based
var $togglehamburger = document.getElementsByClassName(
  "menu-mobile__hamburger"
);
for (var i = 0; i < $togglehamburger.length; i++) {
  $togglehamburger[i].addEventListener("click", function () {
    var isOpen = $slider.classList.contains("slide-in");
    $slider.setAttribute("class", isOpen ? "slide-out" : "slide-in");
  });
}

//close menu on clicking any links

var $toggleclose = document.getElementsByClassName("menu-mobile__toggle");
for (var i = 0; i < $toggleclose.length; i++) {
  $toggleclose[i].addEventListener("click", function () {
    var isOpen = $slider.classList.contains("slide-in");
    $slider.setAttribute("class", isOpen ? "slide-out" : "slide-in");
  });
}
